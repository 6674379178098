<template>

  <div class="home">

    <div
      v-if="!pageLoad"
      class="page-loader"
    >
      <div
        class="spinner-border text-primary"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div class="page-title d-flex py-2 justify-content-between">

      <h1 class="h4 mb-0">
        Корректировка заказа № {{ orderCorrection.number }} от {{ orderCorrection.date | parseDate }}
        <br>
        заказа поставщику № {{ orderCorrection.provider_order.number }} от {{ orderCorrection.provider_order.order_date | parseDate }}
      </h1>

      <div class="d-flex align-items-center">
        <BButton
          class="ml-3"
          variant="warning"
        >Экпорт корректировки
        </BButton>
      </div>

    </div>

    <TableFilter
      :columns="orderCorrectionOrdersFieldsFiltered"
      uniqueKey="table-1"
      @update-selected-columns="columns = $event"
      @search="filter = $event"
      @clear="clearFilterData"
      @open-filter-modal="$root.$emit('bv::toggle::collapse', 'filter')"
    />

    <DefaultTable
      :data="orderCorrection.positions"
      :fields="orderCorrectionOrdersFieldsFiltered"
      :filter="filter"
      :loading="loading"
      :withSpecifiedTotal="getCorrectionTotal"
      emptyText="Нет заказов"
      :noLocalSorting="true"
      @sort-change="sorting"
      @pagination-change="paginationChange"
      @per-page-change="perPageChange"
    />

    <FilterSidebar
      id="filter"
      v-model="filterData"
      @apply="updatePositionsWithFilters(true)"
    />

  </div>

</template>

<script>
import TableFilter from "@/components/TableFilter";
import DefaultTable from "@/components/Tables/Default";
import FilterSidebar from "@/components/FilterSidebar";
import { mapState } from "vuex";

function initialFilterData() {
  return {
    mnemocode: "",
    nomenclature_name: "",
    price_without_vat: "",
    delivery_address: "",
    delivery_time: ["", ""],
  };
}

export default {
  name: "OrderCorrection",
  components: {
    DefaultTable,
    TableFilter,
    FilterSidebar,
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    orderId() {
      return this.$route.params.orderId;
    },
    ...mapState({
      orderCorrection: (state) => state.orderCorrection.orderCorrection,
    }),
    orderCorrectionOrdersFieldsFiltered() {
      let res = [];
      this.fields.forEach((item) => {
        if (this.columns.filter((col) => col === item.label).length) {
          res.push(item);
        }
      });
      return res.length > 0 ? res : this.fields;
    },
    breadcrumbs() {
      return [
        { title: "Главная", link: "/" },
        { title: "Заказ СК", link: "/" },
        {
          title: "Перечень заказов поставщику",
          link: "/provider/purchase-orders",
        },
        {
          title: `Заказ на поставку № ${
            this.orderCorrection.provider_order.number
          } от ${this.$options.filters.parseDate(
            this.orderCorrection.provider_order.order_date
          )}`,
          link: `/provider/purchase-orders/${this.orderId}`,
        },
        {
          title: `Корректировка заказа № ${
            this.orderCorrection.number
          } от ${this.$options.filters.parseDate(this.orderCorrection.date)}`,
        },
      ];
    },
    getCorrectionTotal() {
      return `Всего сумма по документу: без НДС - ${
        this.orderCorrection.common_amount_without_vat || "Не указано"
      } руб., с НДС - ${
        this.orderCorrection.common_amount_with_vat || "Не указано"
      } руб.`;
    },
  },
  data: () => ({
    pageLoad: false,
    columns: [],
    filterData: initialFilterData(),
    fields: [
      {
        key: "mnemocode",
        label: "Мнемокод",
        full: "Мнемокод",
        openDefault: true,
        sortable: true,
      },
      {
        key: "nomenclature_name",
        label: "Номенклатура",
        full: "Номенклатура",
        openDefault: true,
        sortable: true,
      },
      {
        key: "count",
        label: "Кол-во",
        full: "Количество",
        openDefault: true,
        sortable: true,
      },
      {
        key: "unit",
        sortable: true,
        label: "Ед. изм.",
        full: "Единица измерения",
        openDefault: true,
      },
      {
        key: "price_without_vat",
        label: "Цена ₽ (без НДС)",
        full: "Цена ₽ (без НДС)",
        openDefault: true,
        sortable: true,
      },
      {
        key: "amount_without_vat",
        label: "Сумма ₽ (без НДС)",
        full: "Сумма ₽ (без НДС)",
        openDefault: true,
        sortable: true,
      },
      {
        key: "vat_rate",
        label: "Ставка НДС",
        full: "Ставка НДС",
        openDefault: true,
        sortable: true,
      },
      {
        key: "amount_with_vat",
        label: "Сумма ₽ (с НДС)",
        full: "Сумма ₽ (с НДС)",
        openDefault: true,
        sortable: true,
      },
      {
        key: "delivery_time",
        label: "Срок поставки",
        full: "Срок поставки",
        openDefault: true,
        sortable: true,
      },
      {
        key: "delivery_address",
        label: "Адрес поставки",
        full: "Адрес поставки",
        openDefault: true,
        sortable: true,
      },
    ],
    sortingData: {
      sort_field: "",
      sort_order: "",
      page: "",
      per_page: 25,
    },
    filter: "",
    loading: false,
  }),
  methods: {
    async loadPage() {
      this.pageLoad = false;
      await Promise.all([
        this.$store.dispatch("getOrderCorrection", {
          orderId: this.orderId,
          id: this.id,
        }),
        this.$store.dispatch("getOrderCorrectionPositions", {
          orderId: this.orderId,
          id: this.id,
        }),
      ]);
      this.pageLoad = true;
    },
    async updatePositionsWithFilters(clearSort) {
      this.loading = true;
      if (clearSort) {
        this.sortingData.sort_field = "";
        this.sortingData.sort_order = "";
        this.sortingData.page = "";
      }
      await this.$store.dispatch("getOrderCorrectionPositions", {
        id: this.id,
        orderId: this.orderId,
        filters: {
          ...this.sortingData,
          ...this.filterData,
        },
      });
      this.loading = false;
    },
    clearFilterData() {
      this.filterData = initialFilterData();
      this.updatePositionsWithFilters(true);
    },
    sorting(event) {
      this.sortingData.sort_field = event.sortBy;
      this.sortingData.sort_order = event.sortDesc ? "desc" : "asc";
      this.updatePositionsWithFilters();
    },
    paginationChange(page) {
      this.sortingData.page = page;
      this.updatePositionsWithFilters();
    },
    perPageChange(perPage) {
      this.sortingData.page = "";
      this.sortingData.per_page = perPage;
      this.updatePositionsWithFilters();
    },
  },
  mounted() {
    this.fields.forEach((item) => {
      if (item.openDefault) {
        this.columns.push(item.label);
      }
    });
    this.loadPage();
  },
};
</script>

<style scoped>
</style>
